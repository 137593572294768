import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import useTranslation from 'next-translate/useTranslation';
import { TextRegular26 } from '../../VuexyDesign/atoms/Text';
import { TrustedArtist } from '../../VuexyDesign/templates/DistributionLandingLayout';
import { DistributionLandingTrustedItem } from '../../VuexyDesign/molecules/_index';

const ImageRightTestimonials = () => {
  const { t } = useTranslation();
  return (
    <ImageRightContainer className="overflow-hidden  flex-column justify-content-end h-100 w-100 desktop-display-content image-content">
      <div
        className="d-flex flex-column justify-content-end"
        style={{ gap: '26px' }}
      >
        <div style={{ paddingLeft: '20px' }}>
          <TextRegular26 className="text-white">
            {t('register:connectWith') as string}
          </TextRegular26>
        </div>
        <div
          style={{
            height: '261px',
            maxHeight: '261px',
          }}
        >
          <Slider {...settings} className="w-100 d-block">
            {trustedUsers.map((item) => (
              <div className="w-100" key={`trusted-item-${item.id}`}>
                <DistributionLandingTrustedItem
                  className="mx-1"
                  imgUrl={item.imgUrl}
                  name={item.name}
                  description={item.description}
                  job={item.job}
                  onLoginPage
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </ImageRightContainer>
  );
};

export default ImageRightTestimonials;

const ImageRightContainer = styled.div`
  background-image: url('/static/images/register/splash.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  padding: 0px 0px 84px 0px;
`;

const trustedUsers: TrustedArtist[] = [
  {
    id: 1,
    imgUrl: '/static/images/distribution-landing/user-profile1.webp',
    logoUrl: '/static/images/distribution-landing/user-profile1.webp',
    name: 'Jameson Andrews',
    description: 'organisms:trustedArtists.jamesonAndrews',
    job: 'organisms:trustedArtists.artistOnVirpp',
  },
  {
    id: 2,
    imgUrl: '/static/images/distribution-landing/user-profile2.webp',
    logoUrl: '/static/images/distribution-landing/user-profile2.webp',
    name: 'Lamas',
    description: 'organisms:trustedArtists.lamas',
    job: 'organisms:trustedArtists.collabViaVirpp',
  },
  {
    id: 3,
    imgUrl: '/static/images/distribution-landing/user-profile3.webp',
    logoUrl: '/static/images/distribution-landing/user-profile3.webp',
    name: 'DJ Xquizit',
    description: 'organisms:trustedArtists.djXquizit',
    job: 'organisms:trustedArtists.artistOnVirpp',
  },

  {
    id: 4,
    imgUrl: '/static/images/home/johnSmith.webp',
    logoUrl: '/static/images/home/johnSmith.webp',
    name: 'John Smith',
    description: 'organisms:trustedArtists.johnSmith',
    job: 'organisms:trustedArtists.singerSongwriter',
  },
  {
    id: 5,
    imgUrl: '/static/images/home/sarahJohnson.webp',
    logoUrl: '/static/images/home/sarahJohnson.webp',
    name: 'Sara Johnson',
    description: 'organisms:trustedArtists.sarahJohnson',
    job: 'organisms:trustedArtists.electronicMusicProducer',
  },
  {
    id: 6,
    imgUrl: '/static/images/home/davidLee.webp',
    logoUrl: '/static/images/home/davidLee.webp',
    name: 'David Lee',
    description: 'organisms:trustedArtists.davidLee',
    job: 'organisms:trustedArtists.hiphopArtist',
  },
];

const settings = {
  dots: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 10000,
  pauseOnHover: false,
  accessibility: false,
  cssEase: 'linear',
  variableWidth: true,
  breakpoints: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
