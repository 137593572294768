import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { I18nDictionary } from 'next-translate';
import first from 'lodash/first';
import mapValues from 'lodash/mapValues';
import { useRouter } from 'next/router';
import qs from 'query-string';
import useTranslation from 'next-translate/useTranslation';
import { Formik, Form, ErrorMessage, Field, FieldProps } from 'formik';
import { connect, useStore } from 'react-redux';
import Image from 'next/image';
import { ArrowRight } from 'react-feather';
import loadNamespaces from 'next-translate/loadNamespaces';
import {
  API,
  authenticationActions,
  Dispatch,
  userActions,
  StoreType,
  style,
  User,
  useCurrentUser,
  useSetDataLayerAutoLoginFlagSessionStorage,
} from '../api';
import Link from '../components/common/Link';

import { ErrorLabel } from '../components/common/Miscellanea';
import VerificationComponent from '../components/ResendVerificationEmail';
import { login, withAuthSync } from '../utils/auth';
import PageTitle from '../components/common/BrowserPageTitle';
import { registerPusher } from '../utils/registerPusher';
import Logo from '../components/common/Logo';
import { mobile, useIsMobile } from '../style/constants';
import { useAffiliate } from '../utils/affiliate';
import virppLogoIcon from '../public/static/images/Virpp-logo-svg.svg';
import { Input, Checkbox, Button } from '../VuexyDesign/atoms/_index';
import Flex from '../components/common/Flex';
import ImageRightTestimonials from '../components/Register/ImageRightTestimonials';
import { getPlanNamesFromPlanTypes } from '../utils/getPlanNamesFromPlanTypes';
import { addToDataLayerGA } from '../utils/googleAnalytics';
import { VirpPageContext } from './_app';

const DynamicGoogleLoginHandler = dynamic(
  () => import('../components/common/SocialLogin/GoogleLoginHandler'),
);

const LoginForm = ({
  getCurrentUser,
  cb,
}: {
  getCurrentUser: () => Promise<User | undefined>;
  cb: (token: string) => void;
  __lang: string;
  __namespaces?: Record<string, I18nDictionary>;
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [showUnverifiedBtn, setShowUnverifiedBtn] = useState(false);
  const [lastSubmittedEmail, setLastSubmittedEmail] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const affiliate = useAffiliate();
  const user = useCurrentUser();
  const isMobile = useIsMobile();
  const store = useStore() as StoreType;
  const flagForAutoLoginDataLayerSent = useSetDataLayerAutoLoginFlagSessionStorage();
  const {
    query: { redirect, redirectQuery, ...query },
  } = router;

  const finalizeLogin = async (
    token: string,
    setSubmitting?: (flag: boolean) => void,
  ) => {
    await login({ token, cb });
    await getCurrentUser().then((currentUser) => {
      if (currentUser) {
        addToDataLayerGA({
          event: 'login',
          userId: currentUser.id,
          userType: getPlanNamesFromPlanTypes(currentUser.abilities?.plan),
        });
        flagForAutoLoginDataLayerSent.setIsDataLayerSent();
      }
    });

    if (setSubmitting) {
      setSubmitting(false);
    }

    registerPusher(store);
    affiliate.flushAffilateId();
  };

  const redirectToPrefferedPath = () => {
    const redirectPath = redirect && String(redirect);
    if (redirectPath) {
      router.push({
        pathname: redirectPath,
        query: {
          ...query,
          ...(redirectQuery ? qs.parse(redirectQuery as string) : {}),
        },
      });
    }
  };
  /* --------------------------- if user redirection logic after login --------------------------- */
  useEffect(() => {
    const { abilities, id } = user;
    if (abilities) {
      const redirectPath = redirect && String(redirect);
      if (redirectPath) {
        redirectToPrefferedPath();
      } else if (abilities.plan === 'amateur') {
        if (isNewUser) {
          router.push('/become-pro?newuser=true&fire_ga=true');
        } else {
          router.push('/become-pro');
        }
      } else {
        router.push(`/profile/${id}`);
      }
    }
  }, [user]);

  const getImageFromMedia = () => (
    <>
      <Image
        alt="VIRPP Logo"
        priority
        src={virppLogoIcon}
        height={30}
        width={129.07}
        className="noDesktop-display-content  pointer-events-none"
        style={{
          maxWidth: '100%',
        }}
      />
      <Image
        alt="VIRPP Logo"
        priority
        src={virppLogoIcon}
        height={44.16}
        width={190}
        className="desktop-display-content pointer-events-none"
        style={{
          maxWidth: '100%',
        }}
      />
    </>
  );

  return (
    <Outer>
      <PageTitle>{t('common:loginToVirpp') as string}</PageTitle>
      {!isMobile && <ImageRightTestimonials />}
      <LoginContainer>
        <FormContainer>
          <div
            onClick={() => {
              router.push('/');
            }}
            className="w-100 cursor-pointer"
            style={{ paddingBottom: '24px' }}
          >
            {getImageFromMedia()}
          </div>

          {showUnverifiedBtn && (
            <VerificationComponent
              email={lastSubmittedEmail}
              close={() => setShowUnverifiedBtn(false)}
            />
          )}

          <div className="d-flex flex-column w-100">
            <span className="custom-font-text-larger-medium ">
              {t('pricing:welcomeBack') as string} &#129311;
            </span>
            <span className="custom-font-text-small-normal mb-2">
              {t('register:takeYourMusic') as string}
            </span>
          </div>

          <Flex flexDirection="column" width="100%">
            <DynamicGoogleLoginHandler
              finalize={({ token, new_user }) => {
                setIsNewUser(new_user);
                finalizeLogin(token);
              }}
              width={!isMobile ? '400px' : '330px'}
              authType="SIGN_IN"
            />
          </Flex>

          <div className="w-100">
            <div className="divider" style={{ margin: '26px 0px' }}>
              <div className="divider-text text-lowercase text-secondary">
                {t('common:or') as string}
              </div>
            </div>
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
              remember_me: false,
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              setShowUnverifiedBtn(false);
              setLastSubmittedEmail('');
              try {
                const {
                  headers: { authorization: token },
                } = await API.authentication.login(
                  values.email,
                  values.password,
                  values.remember_me,
                );
                finalizeLogin(token, setSubmitting);
              } catch (err) {
                const errorsFromServer = err?.response?.data.data as
                  | Record<string, string | undefined>
                  | undefined;

                // check if user is verified
                if (
                  errorsFromServer?.verified !== undefined &&
                  !errorsFromServer.verified
                ) {
                  setErrors({ email: err?.response?.data?.message });
                  setShowUnverifiedBtn(true);
                  setLastSubmittedEmail(values.email);
                }
                // check other cases
                else if (errorsFromServer?.original) {
                  const singleErrorsFromServer = mapValues(
                    errorsFromServer.original,
                    (val) => first(val),
                  );
                  setErrors(singleErrorsFromServer);
                } else if (errorsFromServer?.error) {
                  setErrors({ email: err?.response?.data?.message });
                }
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form style={{ width: '100%' }}>
                <ErrorMessage name="email" component={ErrorLabel} />
                <Field name="email">
                  {({ field, form }: FieldProps) => (
                    <div className="d-flex flex-column" style={{ gap: '4px' }}>
                      <span className="custom-font-text-small-normal text-secondary">
                        {t('register:placeholders.emailAdress') as string}
                      </span>
                      <Input
                        {...field}
                        autoComplete="username"
                        className="text-dark"
                        name="email"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue('email', e.target.value);
                        }}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage name="password" component={ErrorLabel} />
                <Field type="password" name="password">
                  {({ field, form }: FieldProps) => (
                    <div className="d-flex flex-column" style={{ gap: '4px' }}>
                      <span className="custom-font-text-small-normal text-secondary">
                        {t('register:placeholders.password') as string}
                      </span>
                      <Input
                        {...field}
                        className="text-dark"
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue('password', e.target.value);
                        }}
                      />
                    </div>
                  )}
                </Field>
                <ToggleContainer>
                  <Checkbox
                    name="remember_me"
                    id="remember_me"
                    checked={values.remember_me}
                    onChange={() =>
                      setFieldValue('remember_me', !values.remember_me)
                    }
                    disabled={isSubmitting}
                  />
                  <span>{t('common:keepMeLoggedIn') as string}</span>
                </ToggleContainer>
                <Button disabled={isSubmitting} color="primary">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ gap: '9px' }}
                  >
                    {t('common:login') as string}
                    <ArrowRight size="16" />
                  </div>
                </Button>
              </Form>
            )}
          </Formik>
          <Link href="/reset-password" passHref>
            <div className="w-100">
              <Button
                color="secondary"
                outline
                text={t('register:forgotPassword') as string}
                className="w-100"
              />
            </div>
          </Link>

          <span className="custom-font-text-regular-normal mt-1 w-100 text-center">
            <span style={{ color: '#4B465C' }}>
              {t('register:noAccount') as string}
            </span>{' '}
            <Link
              style={{ color: '#F71454', fontWeight: 400 }}
              href={
                router.query?.redirect
                  ? `/register?${qs.stringify({
                      redirect: router.query.redirect as string,
                    })}`
                  : '/register'
              }
            >
              {t('register:signupInstead') as string}
            </Link>
          </span>
          <div className="w-100">
            <div className="divider" style={{ margin: '18px 0px' }}>
              <div className="divider-text text-secondary">
                {t('register:businessLogin') as string}
              </div>
            </div>
          </div>
          <span className="custom-font-text-regular-normal w-100 text-center">
            <span style={{ color: '#4B465C' }}>
              {t('register:areYouALabel') as string}
            </span>{' '}
            <a
              className="text-info"
              style={{ fontWeight: 400 }}
              href="https://business.virpp.com"
            >
              {t('register:signInHere') as string}
            </a>
          </span>
        </FormContainer>
      </LoginContainer>
    </Outer>
  );
};

LoginForm.getInitialProps = async (ctx: VirpPageContext) => {
  const getTranslations = await loadNamespaces({
    ...ctx,
    pathname: '/login',
  });
  return { ...getTranslations };
};

export default withAuthSync(
  connect(null, (dispatch: Dispatch) => ({
    register: (token: string) =>
      dispatch(authenticationActions.register(token)),
    getCurrentUser: () => dispatch(userActions.getCurrent()),
    cb: (token: string) =>
      dispatch(authenticationActions.verifyLoginOnStartup(token)),
  }))(LoginForm),
);

const Outer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  padding: 32px;

  @media ${mobile} {
    height: auto;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;

  @media ${mobile} {
    width: 330px;
  }

  @media (max-width: 350px) {
    & {
      width: 100%;
      margin: 0 16px;
    }
  }

  button {
    margin-bottom: 10px;
  }

  input {
    margin-bottom: 20px;
  }

  input,
  a,
  button {
    width: 100%;
  }

  ${Logo} {
    display: flex;
    width: 100%;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  a.forgot-pass {
    text-align: center;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 18px;

  span {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 400;
    color: ${style.black};
  }
`;
